@use "constants";
@use "mixins";
@use "global/bases";

.submenu {
	&__links {
		a {
			@include bases.base-link;
			@include bases.base-bold-sans;
			font-weight: normal;
			width: auto;
			text-decoration: none;
		}
	}
}

@include mixins.to-lg {
	.submenu {
		transition: max-height 300ms ease-in-out;
		display: flex;
		flex-direction: column;
		overflow-y: scroll;
		z-index: 10;
		max-height: 0px;
		overflow-y: hidden;

		.orientation-changing & {
			transition: none;
		}

		&.is-active {
			max-height: 50vh;

			& .submenu__links {
				opacity: 1;
			}
		}

		&__image {
			display: none;
		}
		&__links {
			opacity: 0;
			transition: opacity 400ms ease-in-out;
			font-size: calc(var(--fs-body) * 1.5);
			padding: var(--p-rhythm-sm) 0 var(--p-rhythm);
			li + li {
				margin-top: var(--p-rhythm-sm);
			}

			& a {
				font-weight: bold;
			}
		}

		&__column {
			margin-top: var(--p-rhythm);
		}
	}
}

@include mixins.from-lg {
	.submenu {
		padding-top: 52px;
		position: relative;
		&__links {
			background: var(--t-accent-colour);
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			&-single + &-single {
				margin-top: calc(var(--p-rhythm-sm) * 0.75);
			}
			&-single {
				@include bases.underline-link();
				&--mobile-only {
					display: none;
				}
			}
		}
	}
}
