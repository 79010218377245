@use "mixins";
@use "global/bases";

.circle-stat {
	position: relative;
	padding-bottom: 100%;

	&__inner {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 15%;
		&:before {
			content: "";
			position: absolute;
			width: calc(100% - 6px);
			height: calc(100% - 6px);
			top: 3px;
			left: 3px;
			border: 1px solid rgba(0, 0, 0, 0.5);
			border-radius: 100%;
		}
	}

	&__inner,
	&__progress {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	&__progress,
	&__inner:before {
		pointer-events: none;
	}

	&__value,
	&__label {
		text-align: center;
	}
	&__value {
		margin-top: -0.06em;
		font-size: var(--fs-h2);
	}
	&__label {
		font-size: var(--fs-body-sm);
	}

	&--serif &__inner:before {
		border: 1px solid rgba(0, 0, 0, 0.5);
		outline: 2px solid #000;
	}

	&--serif &__value {
		@include bases.base-heading;
		font-size: var(--fs-h2);
	}

	&--dark-background &__inner {
		color: var(--t-text-color-inverted);

		&:before {
			background: var(--t-page-bg-inverted);
		}
	}

	&--dark-background &__value,
	&--dark-background &__label {
		position: relative;
		// required to elevate value & label z-index above &__inner:before
	}
}

.circle-stat-list {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: var(--p-rhythm);
}

@include mixins.from-md {
	.circle-stat-list {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	.circle-stat {
		&__value {
			font-size: var(--fs-h1);
		}

		&__label {
			font-size: var(--fs-body);
		}
	}
}
