/**
 * Media Queries - *Try* and make everything fit within these - use @from/to/between
 */
$bp-tiny: 320px;
$bp-small: 768px;
$bp-medium: 1024px;
// Used to keep header on one line
$bp-large: 1370px;
$bp-very-large: 1920px;

/**
  * Font definitions
	*/
$sans-font:
	"Moderat",
	Helvetica Neue,
	Helvetica,
	Arial,
	sans-serif;
$serif-font: "Blacker Display Regular";

$body-font: $sans-font;
$heading-font: $serif-font;

/**
  * Transitions
  */
$base-anim-rhythm: 300ms;
$transition-default: all $base-anim-rhythm ease-in-out;

$excluded_domains: "button", '[href^="http://localhost:8042/"]',
	'[href^="https://tasmanian.com.au/"]',
	'[href^="https://mark.tasmanian.com.au/"]',
	'[href^="https://bt.neonjungle.com.au/"]',
	'[href^="https://littletasmanian.com.au/"]';

$domains_str: "";
@each $link in $excluded_domains {
	$domains_str: "#{$domains_str}:not(#{$link})";
}

// Any http link, except dev/live/staging
$ext-link-query: '[href^="http"]#{$domains_str}';
$dl-query: '[href^="/documents/"]';
