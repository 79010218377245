@use "mixins";
@use "global/bases";

.form-field {
	$c: &;
	position: relative;
	width: 100%;

	&__field,
	&__label {
		display: block;
		line-height: 1.3;
		background: none;
	}

	&__field {
		@include bases.base-bold-sans;
		text-transform: initial;
		border: 0 none;
		padding: var(--p-rhythm-sm);
		width: 100%;
		appearance: none;
		border-radius: 2px;
		border: 1px solid black;
		color: black;
	}

	&__boolean-group {
		> * + * {
			margin-top: var(--p-rhythm-sm);
		}
	}

	&__label {
		@include bases.base-heading;
		font-size: var(--fs-h3);
		margin-bottom: var(--p-rhythm-sm);
		&-required {
			color: red;
		}
	}

	&__error-messages {
		color: red;
		width: 100%;
		&-single {
			margin-top: 10px;
		}

		a {
			text-decoration: underline;
			display: inline;
		}
	}

	&__help-text {
		margin-top: 0.4em;
		width: 100%;

		& + #{$c}__boolean-group {
			margin-top: 0.6em;
		}
	}

	&__container {
		position: relative;
		&:before {
			content: "";
			width: 12px;
			height: 10px;
			background: url(../images/arrow-down.svg);
			background-size: contain;
			background-repeat: no-repeat;
			position: absolute;
			right: 14px;
			top: 50%;
			transform: translateY(-50%);
			pointer-events: none;
		}
	}

	&--cols-auto {
		#{$c}__boolean-group {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			> * + * {
				margin-top: 0;
			}
			.form-boolean {
				display: inline-block;
			}
		}
	}
}

@include mixins.from-md {
	.form-field {
		$c: &;
		font-size: 12px;

		&--select {
			&:before {
				right: 20px;
				bottom: 22px;
			}
		}

		&--cols-2 {
			#{$c}__boolean-group {
				columns: 2;
			}
		}

		&__boolean-group {
			> * + * {
				margin-top: calc(var(--p-rhythm-sm) / 2);
			}
		}
	}
}
