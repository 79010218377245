$indexBadgeSize: 36px;

.application-form {
	margin-bottom: var(--p-rhythm-sm);

	&__intro {
		margin-bottom: var(--p-rhythm-sm);
	}

	.form-field {
		&__field {
			font-weight: 300;
			letter-spacing: normal;
			text-transform: none;
		}
	}

	.product-formset {
		&__form {
			label[for*="name"] {
				position: relative;
				padding-left: 44px;

				&:before,
				&:after {
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
				}

				&::before {
					content: "";
					height: $indexBadgeSize;
					width: $indexBadgeSize;
					background: black;
					border-radius: 100%;
					margin-top: -2px;
				}

				&::after {
					content: "*";
					width: $indexBadgeSize;
					text-align: center;
					color: white;
				}
			}
		}
	}

	@for $i from 1 through 50 {
		.product-formset__form:nth-of-type(#{$i}) {
			label[for*="name"]::after {
				content: "#{$i}";
			}
		}
	}
}
