@use "constants";
@use "mixins";
@use "global/bases";

.l-search-overlay {
	height: calc(100vh - var(--header-height) + 2px);
	width: 100%;
	position: fixed;
	top: calc(var(--header-height) - 1px);
	left: 0;
	background: var(--t-accent-colour);
	color: white;
	z-index: 110;
	pointer-events: none;
	opacity: 0;
	transition: opacity 300ms ease-in-out;
	padding: var(--p-rhythm-lg) var(--grid-gutter);
	overflow-y: auto;

	&__results {
		padding-bottom: var(--grid-gutter);
	}

	&__closer {
		position: absolute;
		right: var(--grid-gutter);
		top: var(--grid-gutter);
	}

	&__no-results {
		@include bases.base-heading;
		font-size: var(--fs-body-lg);
		margin-bottom: var(--p-rhythm);
	}
	&.is-open {
		opacity: 1;
		pointer-events: all;

		.form-field:after {
			width: 100%;
			transition: 350ms ease-in;
			transition-delay: 310ms;
		}
	}

	&__form {
		margin: var(--p-rhythm) 0;
		position: relative;

		.form-field {
			&:after {
				content: "";
				height: 1px;
				background-color: white;
				width: 0%;
				position: absolute;
				bottom: 0;
				left: 0;
			}
			&__field {
				@include bases.base-heading;
				font-size: var(--fs-body-lg);
				padding: 0;
				color: white;
				text-transform: none;
				position: relative;
				border: none;
				border-bottom: 1px solid transparent;

				&:hover,
				&:active,
				&:focus {
					outline: 0px solid transparent;
				}

				&::-moz-focus-inner {
					border: 0;
				}
				&::placeholder {
					opacity: 1;
					color: white !important;
				}
			}
		}

		&-submit {
			@include bases.base-bold-sans;
			letter-spacing: 0.15em;
			font-weight: normal;
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			width: auto;
		}
	}

	&__suggestions {
		&-title {
			margin-bottom: var(--p-rhythm-sm);
		}

		&-links {
			display: grid;
			grid-template-columns: 1fr;
			gap: 0.5em;

			& a {
				@include bases.base-link;
				width: auto;
				line-height: 1.2;
			}
		}

		& > .btn {
			+ .btn {
				margin-left: var(--p-rhythm-sm);
			}
		}
	}
}

@include mixins.from-md {
	.l-search-overlay {
		display: grid;
		grid-template-columns: 8fr 2fr;
		&__main {
			padding-left: #{mixins.grid-unit(1, false)};
			padding-right: #{mixins.grid-unit(1, false)};
		}
		&__social {
		}
		&__form {
			margin: 0;
			margin-bottom: var(--p-rhythm-lg);
			.form-field__field {
				padding-right: 100px;
				font-size: var(--fs-h3);
			}
		}

		&__suggestions {
			&-links {
				grid-template-columns: 1fr 1fr 1fr;
				gap: 1.6em;
			}
		}
	}

	.search-open {
		.l-site-header {
			.l-navigation {
				opacity: 0;
				pointer-events: none;
			}
			&__icon-link:not(&__icon-link--search, [data-navigation-trigger]) {
				opacity: 0;
				pointer-events: none;
			}
		}
	}
}
