@use "mixins";
@use "constants";

@use "global/bases";

.application-steps {
	@include bases.base-heading;
	display: flex;
	align-items: flex-end;
	justify-content: end;
	gap: 1rem;

	&__label {
		font-size: var(--fs-h2);
		margin-bottom: calc(var(--p-rhythm-sm) / 2);
	}

	&__steps {
		display: flex;
		align-items: center;
		gap: 1rem;
	}

	&__step {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 0.5rem;
		font-size: var(--fs-h1);
		text-align: center;
		line-height: 1;
		border-radius: 100%;
		border: var(--border);
		background: var(--t-accent-colour-inverted);
		width: 66px;
		height: 66px;

		&--active {
			background-color: var(--t-accent-colour);
			color: var(--t-accent-colour-inverted);
			border-color: var(--t-accent-colour-inverted);
		}
	}
}

@include mixins.from-md {
	.application-steps {
		right: calc(
			var(--grid-col) * 1 + 2 * var(--grid-gutter) + var(--p-rhythm-lg) * -1
		);

		&__steps {
			gap: 1.5rem;
		}

		&__step {
			width: 90px;
			height: 90px;
		}
	}
}
