@use "global/bases";

.credited-media {
	height: 100%;
	position: relative;
	&__asset {
		height: 100%;
		picture {
			display: block;
			height: 100%;
			img {
				height: 100%;
				object-fit: cover;
			}
		}
	}
	&__attribution {
		$a: &;
		position: absolute;
		z-index: 1;
		bottom: var(--p-rhythm);
		right: var(--p-rhythm);

		&-icon {
			svg {
				width: 41px;
				height: auto;
				display: block;
			}
		}
		&-label {
			@include bases.base-bold-sans;
			transition: opacity 150ms ease-in-out;
			color: white;
			padding: 0 8px;
			position: absolute;
			height: 100%;
			top: 0;
			right: 100%;
			opacity: 0;
			line-height: 1;
			display: flex;
			align-items: center;
		}
		&:hover,
		&:focus,
		&:active {
			#{$a}-label {
				opacity: 1;
			}
		}
	}
}
