.sort-by {
	padding-bottom: var(--p-rhythm-sm);
	margin: var(--p-rhythm) 0;
	margin-left: var(--p-rhythm-sm);
	width: max-content;
	position: relative;
	&__button {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: var(--p-rhythm-sm);
		border: 1px solid black;
		text-transform: uppercase;
		letter-spacing: 2px;
		font-weight: bold;
		padding: 0.2rem var(--p-rhythm-sm);
		width: fit-content;
		&:hover {
			cursor: pointer;
		}
	}

	&__options {
		border-top: 2px solid black;
		position: absolute;
		white-space: nowrap;
		top: 3.5rem;
		left: 0;
		z-index: 100;
		&-container {
			display: none;
			padding: var(--p-rhythm-sm) var(--p-rhythm);
			background-color: white;
			&.active {
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
			}
		}
		& button {
			&:hover {
				cursor: pointer;
				text-decoration: underline;
			}
		}
	}
}
