@use "mixins";
@use "global/bases";

.l-site-footer {
	padding: var(--grid-gutter);
	background: var(--t-accent-colour);
	color: white;

	&__logo {
		display: block;
		width: var(--footer-logo-width);
		img {
			width: auto;
			height: 100%;
		}
	}
	&__links-secondary,
	&__menu {
		@include bases.base-heading;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		a {
			border-bottom: 1px solid transparent;
			width: auto;
			display: inline-block;
			&:hover,
			&:focus,
			&:active {
				border-bottom-color: currentColor;
			}
		}
	}
	&__menu {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		a {
			font-size: var(--fs-footer-menu);

			& + a {
				margin-top: 0.5em;
			}
		}
	}
	&__links {
		&-secondary {
			a {
				font-size: var(--fs-h3);
			}
		}
		&-gov {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			a {
				@include bases.base-link;
				@include bases.ext-link-icon {
					&:after {
						background-image: url("../images/external-link-white.svg");
					}
				}
				width: auto;
				+ a {
					margin-top: 1em;
				}
			}
		}
	}
	&__backlink {
		width: 220px;
	}
	&__acknowledgement {
		font-size: var(--fs-body-lg);
		font-weight: 300;
	}
	&__copyright {
		font-weight: 300;
		img {
			width: 70px;
			margin-bottom: var(--p-rhythm);
		}
	}
}

@include mixins.to-md {
	.l-site-footer {
		> * + * {
			margin-top: var(--p-rhythm-lg);
		}
		&__links {
			&-secondary {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: var(--p-rhythm);
			}
			&-gov {
				margin-top: var(--p-rhythm-lg);
			}
		}
	}
}

@include mixins.from-md {
	.l-site-footer {
		padding: #{mixins.grid-unit(1, true)};
		padding-top: var(--p-rhythm-lg);

		position: relative;
		z-index: 101;

		display: grid;
		gap: var(--p-rhythm);
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-areas:
			"logo . ."
			"menu links acknowledgement"
			"menu links copyright";

		&--subsite {
			grid-template-areas:
				"logo . ."
				"menu links acknowledgement"
				"menu links copyright"
				"backlink . copyright";
		}

		&__logo {
			grid-area: logo;
			margin-bottom: calc(var(--p-rhythm-lg) * 2);
			height: var(--footer-logo-height);
			width: auto;
		}
		&__links {
			grid-area: links;
		}
		&__menu {
			grid-area: menu;
		}
		&__acknowledgement {
			grid-area: acknowledgement;
		}
		&__links-secondary,
		&__menu {
			a + a {
				margin-top: 0.5em;
			}
		}
		&__links-secondary {
			margin-bottom: var(--p-rhythm-lg);
		}

		&__backlink {
			grid-area: backlink;
			margin-top: var(--p-rhythm-lg);
		}

		.icon-bar {
			position: absolute;
			right: calc(#{mixins.grid-unit(1, true)} / 2);
			transform: translateX(50%);
			top: calc(var(--footer-logo-height) + var(--p-rhythm-lg) * 3);
			display: flex;
			flex-direction: column;
			&__item {
				margin-left: 0;
				margin-bottom: var(--p-rhythm-sm);
			}
		}
		&__copyright {
			grid-area: copyright;
			display: flex;
			align-items: flex-end;
			img {
				margin-right: var(--grid-gutter);
				margin-bottom: 0;
				margin-top: -4px;
				width: 70px;
			}
			p {
				margin-bottom: -4px;
			}
		}
	}
}

@include mixins.from-lg {
	.l-site-footer {
		&__acknowledgement {
			padding-right: #{mixins.grid-unit(1, true)};
		}
	}
}
