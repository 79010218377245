$anim-speed: 270ms;

@keyframes mouse-bounce {
	0% {
		transform: translateY(0px);
	}
	70% {
		transform: translateY(0px);
	}
	80% {
		transform: translateY(8px);
	}
	85% {
		transform: translateY(5px);
	}
	90% {
		transform: translateY(8px);
	}
	100% {
		transform: translateY(0px);
	}
}

.l-scroll-tracker__icon,
.l-page-fold__scroll-icon {
	animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
	animation: mouse-bounce 4s infinite;
}
@mixin clipInToRightInital {
	clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
	will-change: transform;
	backface-visibility: hidden;
}

@keyframes clipInToRight {
	0% {
		clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
	}
	99% {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	}
	100% {
		clip-path: none;
	}
}

@mixin clipInToBottomInital {
	clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
	will-change: transform;
	backface-visibility: hidden;
}

@keyframes clipInToBottom {
	0% {
		clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
		opacity: 0;
	}
	99% {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
		opacity: 1;
	}
	100% {
		clip-path: none;
		opacity: 1;
	}
}

@keyframes visible {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@mixin childSteps($offset: 0, $max: 6) {
	> * {
		animation: visible $anim-speed ease-in-out forwards;
	}
	& > * {
		animation-delay: $anim-speed * ($max + 1 + $offset);
	}
	@for $i from 1 through 6 {
		// Add to each child in-order an increasing delay
		& > *:nth-child(#{$i}) {
			animation-delay: $anim-speed * ($i + $offset);
		}
	}
}

%content-cta-initial {
	.content-cta__cover {
		background: var(--t-accent-colour-light);
	}
	.content-cta__copy {
		opacity: 0;
		> * {
			opacity: 0;
		}
		&-title {
			@include clipInToRightInital;
			opacity: 1;
		}
	}

	.credited-media__asset {
		@include clipInToBottomInital;
	}
}

@mixin contentCtaAnimate($include-children: true) {
	.credited-media__asset {
		animation: clipInToRight $anim-speed cubic-bezier(0.23, 1, 0.32, 1) forwards;
	}
	.content-cta__copy {
		animation: visible $anim-speed ease-in-out forwards;
		animation-delay: $anim-speed;
		@if $include-children {
			@include childSteps(1.5);
		} @else {
			> * {
				animation: visible $anim-speed ease-in-out forwards;
			}
		}
		&-title {
			animation: clipInToBottom $anim-speed * 2 cubic-bezier(0.23, 1, 0.32, 1)
				forwards;
		}
	}
}

.l-page-fold {
	@extend %content-cta-initial;
	@include contentCtaAnimate;
}
.home {
	@extend %content-cta-initial;

	.l-page__section.is-visible {
		@include contentCtaAnimate;
	}
}

.l-prose-blocks {
	&__block {
		transition: all $anim-speed * 1.5 ease-in-out;
		opacity: 0;
		transform: translateY(50px);

		.step-list,
		.tile-list,
		.card-list {
			& > * {
				opacity: 0;
			}
		}

		.content-cta {
			@extend %content-cta-initial;
		}

		.richtext-over-image {
			&__cover {
				opacity: 0;
				transition:
					all $anim-speed ease-in-out,
					$anim-speed * 1.5;
			}

			&__copy {
				@include clipInToRightInital();
				> * {
					opacity: 0;
				}
			}
		}

		&.is-visible {
			opacity: 1;
			transform: translateY(0);

			.tile-list,
			.step-list,
			.card-list {
				@include childSteps();
			}

			// When withing a content page or listing, we want the text to animate in more quickly
			.content-cta {
				@include contentCtaAnimate($include-children: false);
			}

			.richtext-over-image {
				&__cover {
					opacity: 1;
				}

				&__copy {
					animation: clipInToRight $anim-speed * 2
						cubic-bezier(0.23, 1, 0.32, 1) forwards;
					animation-delay: $anim-speed * 2;
					@include childSteps(2);
				}
			}
		}
	}
}
