@use "mixins";
@use "constants";

@use "global/bases";

.card {
	$c: &;

	display: block;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: var(--fs-body);

	align-self: start;

	&__image {
		img {
			width: 100%;
			height: auto;
		}
	}

	&__details {
		padding: 0 var(--p-rhythm);
		border-left: var(--border);
		flex: 1;
	}

	&__image + &__details {
		padding-top: var(--p-rhythm);
	}

	&__title {
		@include bases.base-heading;
		margin-bottom: var(--p-rhythm);
		font-size: var(--fs-h3);
	}
	&__subtitle + &__link-label {
		margin-top: var(--p-rhythm);
	}

	&__link-label {
		@include bases.base-bold-sans;
		letter-spacing: 0;
		display: inline-block;
		text-decoration: underline;
		text-underline-offset: 0.1em;
		text-transform: none;
	}

	&--light {
		--border: 1px solid var(--t-text-color-inverted);
		color: var(--t-text-color-inverted);
	}

	&#{constants.$ext-link-query} {
		#{$c}__link-label {
			@include bases.ext-link-icon-inner;
		}
	}

	&:hover,
	&:focus,
	&:active {
		#{$c}__link-label {
			text-decoration-thickness: 2px;
		}
	}
}

.card-list {
	display: grid;
	grid-template-columns: 1fr;
	gap: 30px;

	@include mixins.from-md {
		gap: 60px 20px;
	}
}

@include mixins.from-md {
	.card {
		$c: &;
		&__details {
			// The20 exception
			padding: 30px;
			&--compact {
				padding-top: 0;
				padding-bottom: 0;
			}
		}

		&__image + &__details {
			padding-top: 30px;
		}
		&__image {
			overflow: hidden;
			img {
				transition: transform 4s ease-out;
				transform: scale(1.0001);
				will-change: transform;
			}
		}
		&:hover,
		&:focus,
		&:active {
			#{$c}__image {
				img {
					transform: scale(1.1);
				}
			}
		}
	}
	.card-list {
		grid-template-columns: 1fr 1fr 1fr;
		&--4x {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
		.card {
			height: 100%;
		}
	}
}
