@use "mixins";

@use "global/bases";

.tile {
	@include bases.base-bold-sans;
	line-height: 1.5;
	border: var(--border);
	padding: var(--p-rhythm-sm);
	transition: 250ms ease-in-out;
	display: flex;
	align-items: center;

	@include bases.ext-link-icon {
		&:after {
		}
		&:hover,
		&:focus,
		&:active {
			&:after {
				background-image: url("../images/external-link-white.svg");
			}
		}
	}
	&:hover,
	&:focus,
	&:active {
		background-color: black;
		color: white;
	}
}

.tile-list {
	display: grid;
	grid-template-columns: 1fr;
	gap: var(--p-rhythm-sm);
}

@include mixins.from-md {
	.tile-list {
		grid-template-columns: 1fr 1fr 1fr;
		&--4x {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
	}
}
