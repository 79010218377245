@use "global/bases";
@use "mixins";

.tab-name-container {
	display: flex;
	justify-content: space-between;
}

.tab-content {
	display: none;
	&.active {
		display: block;
	}
}

.tab-button {
	cursor: pointer;
	color: var(--store-dark-grey);
	width: max-content;
	&.active {
		color: black;
		letter-spacing: 0;
		display: inline-block;
		text-decoration: underline;
		text-underline-offset: 0.3em;
		text-transform: none;
	}
}
