@use "mixins";

.basket-item {
	display: grid;
	grid-template-columns: 150px 1fr;
	gap: var(--p-rhythm);
	margin-bottom: var(--p-rhythm);

	&__title {
		font-weight: bold;
	}

	&__name {
		font-weight: bold;
	}

	&__quantity {
		display: flex;
		align-items: center;
		gap: 0.7rem;
	}

	&__error {
		color: red;
	}

	&__increase,
	&__decrease {
		background: none;
		border: none;
		color: white;
		cursor: pointer;
		font-weight: bold;
		padding: 0;
		width: auto;
		display: flex;
		align-items: center;

		& > svg {
			width: 12px;
			height: 18px;
		}

		&--summary {
			color: black;
		}
	}

	&__total {
		font-weight: normal;
	}

	&__remove {
		font-weight: 200;
		color: var(--store-dark-grey);
		cursor: pointer;

		&--summary {
			position: absolute;
			top: 0;
			right: 0;
			width: 1.5rem;
			aspect-ratio: 1;
			svg {
				background-color: white;
				stroke: white;
				stroke-width: 1px;
				fill: currentColor;
			}
		}
	}

	&__info {
		display: flex;
		flex-direction: column;
		gap: 0.3em;
		justify-content: space-between;

		&--summary {
			position: relative;
			padding: var(--p-rhythm-sm) 0 var(--p-rhythm) 0;
		}
	}
}

@include mixins.from-sm {
	.basket-item {
		grid-template-columns: 200px 1fr;
	}
}
