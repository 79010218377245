.spinner {
	margin: var(--p-rhythm) auto;
	animation: rotate 2s linear infinite;
	width: 50px;

	&__path {
		stroke: var(--t-text-color);
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}
}

// jacked from https://codepen.io/supah/pen/BjYLdW
@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -124;
	}
}
