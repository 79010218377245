@use "mixins";
@use "global/bases";

.pagination {
}

@include mixins.from-md {
	.pagination {
		display: flex;
		justify-content: space-between;
		&__next {
			margin-left: auto;
		}
	}
}
