@use "constants";
@use "mixins";
@use "global/bases";

@forward "fonts";

html {
	width: 100%;
	background: var(--t-page-bg-color);
}

body {
	@include bases.body-font;
	font-size: 16px; // Changing this will impact rem based calculations
	color: var(--t-text-color);
	margin: 0;
	min-height: 100vh;

	* {
		line-height: 1.35;
		font-size: var(--fs-body);
	}

	&.preload {
		* {
			transition: none !important;
		}
	}

	&.search-open {
		overflow: hidden;
	}

	@include mixins.to-md {
		&.basket-open {
			overflow: hidden;
		}
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include bases.base-heading;
}

.lede {
	font-size: 1.2em;
}

.richtext,
.rich-text {
	p {
		font-weight: 300;
	}
	> * + * {
		margin-top: 0.4em;
	}
	> * + h1,
	> * + h2,
	> p + p {
		margin-top: 1em;
	}
	> *:first-child {
		margin-top: 0;
	}
	> *:last-child {
		margin-bottom: 0;
	}

	a:not([class]) {
		@include bases.base-link;
		display: inline;
	}

	ul,
	ol {
		padding-left: 1em;
	}

	ul {
		li {
			list-style: disc;
		}
	}

	h2 {
		font-size: var(--fs-h2);
	}

	h3 {
		font-size: var(--fs-h3);
	}

	h4 {
		font-size: var(--fs-h3);
	}

	h5 {
		@include bases.base-bold-sans;
		font-size: var(--fs-body-lg);

		& + p {
			margin-top: 0;
		}
	}

	blockquote {
		@include bases.base-heading;
		font-size: var(--fs-h3);
		line-height: 1.2;
		margin-left: 0;
		margin-right: 0;

		&:before {
			content: "“";
		}
		&:after {
			content: "”";
		}
	}

	table {
		tr {
			border-bottom: 1px solid;
			&:last-child {
				border-bottom: 0 none;
			}
		}
		th,
		td {
			vertical-align: top;
			text-align: left;
		}
		th {
			font-weight: normal;
			padding-bottom: 0.2em;
			padding-right: 0.4em;
		}
		td {
			padding-top: 0.1em;
			padding-bottom: 0.2em;
			padding-right: 0.4em;
		}
	}

	&-image {
		position: relative;

		&.full-width {
			width: 100%;
			height: auto;
		}
	}
}

@include mixins.from-md {
	.richtext,
	.rich-text {
		> * + * {
			margin-top: 1em;
		}
		*:first-child {
			margin-top: 0;
		}
		*:last-child {
			margin-bottom: 0;
		}

		&-image {
			&.left,
			&.right {
				max-width: 50%;
				display: block;
			}
			&.right {
				margin-left: auto;
			}
		}
	}
}
