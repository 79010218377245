@use "mixins";

.l-prose-blocks__block .wordmark {
	display: grid;
	grid-gap: calc(var(--p-rhythm) * 1.5);
	background: var(--t-accent-colour);
	color: var(--t-text-color-inverted);
	padding: calc(var(--p-rhythm) * 1.5);

	&__image {
		max-width: 100px;
	}

	&__text {
		display: flex;
		flex-direction: column;
		margin-top: -5px;
	}

	&__title {
		font-weight: bold;
	}

	&__cta-link {
		text-align: center;
	}
}

@include mixins.from-md {
	.l-prose-blocks__block .wordmark {
		grid-template-columns: 1fr 3fr 1fr;

		&__image {
			max-width: initial;
		}
	}
}
