@use "mixins";
@use "global/bases";

.responsive-table {
	&__table {
		position: relative;
		padding-bottom: 100%;
		border-collapse: collapse;
		width: 100%;
	}

	&__mobile-column-breaks__column-header {
		@include bases.body-font;
	}
	&__mobile-column-breaks__column-cell {
		p + p {
			margin-top: var(--p-rhythm);
		}
	}

	&--cell-align-left &__mobile-column-breaks__column-cell,
	&--cell-align-left tbody td,
	&--column-header-align-left &__mobile-column-breaks__column-header,
	&--column-header-align-left thead th,
	&--row-header-align-left tbody th {
		text-align: left;
	}

	&--cell-align-center &__mobile-column-breaks__column-cell,
	&--cell-align-center tbody td,
	&--column-header-align-center &__mobile-column-breaks__column-header,
	&--column-header-align-center thead th,
	&--row-header-align-center tbody th {
		text-align: center;
	}

	&--cell-align-right &__mobile-column-breaks__column-cell,
	&--cell-align-right tbody td,
	&--column-header-align-right &__mobile-column-breaks__column-header,
	&--column-header-align-right thead th,
	&--row-header-align-right tbody th {
		text-align: right;
	}

	&--cell-style-none &__mobile-column-breaks__column-cell,
	&--cell-style-none tbody td,
	&--column-header-none &__mobile-column-breaks__column-header,
	&--column-header-none thead th,
	&--row-header-none tbody th {
		font-weight: normal;
	}
	&--cell-style-bold &__mobile-column-breaks__column-cell,
	&--cell-style-bold tbody td,
	&--column-header-bold &__mobile-column-breaks__column-header,
	&--column-header-bold thead th,
	&--row-header-bold tbody th {
		font-weight: bold;
	}
	&--cell-style-h4 &__mobile-column-breaks__column-cell p,
	&--cell-style-h4 tbody td p,
	&--column-header-h4 &__mobile-column-breaks__column-header,
	&--column-header-h4 thead th,
	&--row-header-h4 tbody th {
		@include bases.base-heading;
		font-size: var(--fs-h3);
	}
	&--cell-style-h5 &__mobile-column-breaks__column-cell p,
	&--cell-style-h5 tbody td p,
	&--column-header-h5 &__mobile-column-breaks__column-header,
	&--column-header-h5 thead th,
	&--row-header-h5 tbody th {
		@include bases.base-bold-sans;
		font-size: var(--fs-body-lg);
	}

	thead {
	}

	th,
	td {
		text-align: left;
		border: 1px solid var(--t-text-color);
		padding: 1.5rem;

		p + p {
			margin-top: var(--p-rhythm);
		}
	}

	&--tight {
		th,
		td {
			padding: 0.5rem;
		}
	}

	td,
	th {
		&.right {
			text-align: right;
		}
	}

	td:first-child,
	th:first-child {
		border-left: none;
	}
	td:last-child,
	th:last-child {
		border-right: none;
	}

	tr {
		&.no-border {
			td,
			th {
				border: none;
			}
		}
	}

	&__title {
		@include bases.base-heading;
		font-size: var(--fs-h3);
		margin-bottom: var(--p-rhythm);
	}

	&__mobile-column-breaks {
		display: none;
		&__column {
			border-bottom: 1px solid var(--t-text-color);
			&-header {
				padding: 1rem;
				margin: 0;
				border-top: 1px solid var(--t-text-color);
			}
			&-cell {
				font-size: var(--fs-body);
				padding: 1rem;
				margin: 0;
				border-top: 1px solid var(--t-text-color);
			}

			& + & {
				margin-top: 2rem;
			}
		}
	}

	&__mobile-scroll-right-tip {
		display: none;
		font-size: var(--fs-body-sm);
		padding-top: 0.75rem;

		&:after {
			content: "";
			position: relative;
			top: -0.15rem;
			width: 1.1em;
			min-width: 1.1em;
			height: 1.1em;
			margin-left: 0.5rem;
			background-size: contain;
			background-repeat: no-repeat;
			display: inline-block;
			vertical-align: bottom;
			background-image: url("../images/right-caret.svg");
		}
	}
}

@include mixins.to-md {
	.responsive-table {
		&--mobile-layout-column-breaks &__mobile-column-breaks {
			display: block;
		}

		&--mobile-layout-column-breaks &__table {
			display: none;
		}

		&--mobile-layout-scroll-right {
			overflow-x: scroll;
			padding-bottom: 1rem;
		}

		&__mobile-scroll-right-tip {
			display: block;
		}
	}
}
