@use "mixins";

.l-form {
	$c: &;
	--form-spacing: 15px;

	.form-field,
	.rich-text,
	.btn {
		+ .form-field,
		+ .rich-text,
		+ .btn,
		+ #{$c}__heading {
			margin-top: calc(2 * var(--form-spacing));
		}
	}

	&__heading {
		font-size: var(--fs-h2);
		margin-bottom: var(--form-spacing);
	}

	&__disclaimer {
		margin: 2rem 0 2rem 0;
		font-style: italic;
	}

	&__watermark {
		margin-bottom: 4rem;
		font-weight: bold;
		font-style: italic;
	}

	&__link {
		display: inline;
		text-decoration: underline;
	}

	&__boolean-group {
		.form-boolean {
			margin-bottom: var(--form-spacing);
		}
	}
}

@include mixins.from-md {
	.l-form {
		--form-spacing: 30px;
		padding-right: #{mixins.grid-unit(3, true)};

		&--full-width {
			padding-right: 0;
		}

		&__inner {
			padding-right: #{mixins.grid-unit(3, true)};
		}
	}
}
