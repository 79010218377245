@use "constants";
@use "mixins";
@use "global/bases";

.video-player {
	// Hide everything in here untile plyr has loaded
	> *:not(.plyr) {
		opacity: 0;
	}

	.plyr {
		--plyr-range-thumb-height: 0px;
		--plyr-range-fill-background: white;
		--plyr-color-main: transparent;
		--plyr-control-spacing: 5px;
		--plyr-control-icon-size: 14px;
		--plyr-range-track-height: 3px;

		height: 100%;

		&__poster {
			background-size: cover;
		}

		&__controls {
			display: flex;
			flex-wrap: wrap;
			padding: var(--grid-gutter);
			.plyr__progress__container {
				flex: 1;
				flex-basis: 100%;
				width: 100%;
				margin-bottom: 8px;
				input[type="range"] {
					width: 100%;
				}
			}
			&__item {
				margin: 0;
			}
			.plyr__time {
				margin-right: auto;
			}
			.plyr__volume {
				min-width: 0;
				max-width: none;
				width: auto;
			}
			[data-plyr="play"] {
				margin-left: calc(-1 * (var(--plyr-control-spacing) - 1px));
			}
			[data-plyr="fullscreen"] {
				margin-right: calc(-1 * var(--plyr-control-spacing));
			}
		}

		&__captions {
			transform: translateY(-90px) !important;
		}

		input[type="range"]::-webkit-slider-runnable-track {
			border-radius: 0;
			background-color: none;
			background-image: linear-gradient(
					to right,
					currentColor var(--value, 0%),
					transparent var(--value, 0%)
				),
				linear-gradient(
					to bottom,
					rgba(white, 0) 1px,
					rgba(white, 1) 1px,
					rgba(white, 1) 2px,
					rgba(white, 0) 2px
				);
		}
	}
	video {
		object-fit: cover;
	}
}

@include mixins.from-md {
	.video-player {
		.plyr {
			&__controls {
				padding-left: 0;
				padding-right: 0;
				padding-bottom: var(--p-rhythm);
				background: none;
				max-width: 560px;
				margin: 0 auto;
			}
		}
	}
}
