@use "constants";
@use "mixins";

@mixin heading-font() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: constants.$heading-font;
}

@mixin body-font() {
	letter-spacing: 0;
	font-weight: 300;
	font-family: constants.$body-font;
}

@mixin base-heading() {
	@include heading-font;
	font-weight: normal;
	line-height: 1;
	letter-spacing: 0;
	margin: 0;
}

@mixin base-bold-sans() {
	font-family: constants.$sans-font;
	letter-spacing: 0.2em;
	font-weight: bold;
	text-transform: uppercase;
}

@mixin ext-link-icon-inner {
	&:after {
		content: "";
		width: 1em;
		min-width: 1em;
		height: 0.97em;
		transition: background-image 250ms ease-in-out;
		background-image: url("../images/external-link-black.svg");
		background-size: contain;
		background-repeat: no-repeat;
		display: inline-block;
		vertical-align: bottom;
		margin-left: 0.5em;
	}

	&#{constants.$dl-query} {
		&:after {
			background-image: url("../images/download.svg");
			margin-left: 0.3em;
		}
	}
}

@mixin ext-link-icon() {
	&#{constants.$ext-link-query},
	&.external {
		@include ext-link-icon-inner;
		@content;
	}
}

@mixin dl-link-icon() {
	&#{constants.$dl-query} {
		@include ext-link-icon-inner;
		@content;
	}
}

@mixin base-link($include-icon: true) {
	font-weight: bold;
	color: inherit;
	display: inline-block;
	position: relative;
	width: auto;
	text-decoration: underline;
	text-underline-offset: 4px;
	&:hover,
	&:focus,
	&:active {
		text-decoration-thickness: 2px;
	}
	@if $include-icon {
		@include dl-link-icon;
		@include ext-link-icon {
			&:after {
				vertical-align: middle;
				margin-left: 0.3em;
			}
		}
	}
}

@mixin underline-link($thickness: 1px) {
	position: relative;
	&:after {
		content: "";
		position: absolute;
		width: 0%;
		left: 0;
		height: #{$thickness};
		bottom: -4px;
		transition: width 250ms ease-in-out;
		background-color: currentColor;
	}

	&:hover,
	&:focus,
	&:active {
		&:after {
			width: 100%;
		}
	}
}
