@use "mixins";

.l-page {
	$c: &;

	margin: 0 auto;
	position: relative;
	z-index: 1;
	&__section {
		&--padded {
			padding: calc(var(--p-rhythm) * 0.6);
		}
	}

	.home & {
		#{$c}__section {
			--video-position-x: center;
			--video-position-y: center;
			.content-cta__cover-video video {
				object-position: var(--video-position-x) var(--video-position-y);
			}
		}
	}
}
