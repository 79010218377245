@for $i from 1 through 5 {
	@keyframes fade-#{$i} {
		$start: max($i - 1, 0) * 20%;
		$middle: max($i - 1, 0) * 20% + 10%;
		$end: $i * 20%;
		#{$start} {
			transform: translateY(20px);
			opacity: 0;
		}
		#{$middle - 8%} {
			transform: translateY(0);
			opacity: 1;
		}
		#{$middle + 8%} {
			transform: translateY(0);
			opacity: 1;
		}
		#{$end} {
			transform: translateY(-20px);
			opacity: 0;
		}
	}
}

.icon-cycle {
	position: relative;
	width: 22px;
	height: 22px;
	svg {
		width: 22px;
		height: 22px;
		position: absolute;
		left: 0;
		top: 50%;
		margin-top: -11px;
		opacity: 0;
		@for $i from 1 through 5 {
			&:nth-child(#{$i}) {
				animation: fade-#{$i} 15s infinite ease-in-out;
			}
		}
	}
}
