@use "mixins";

.product-formset {
	&__form {
		margin: 2.5rem 0;
	}

	&__columns {
		margin-bottom: 1.5rem;
		--form-spacing: 0.5rem;

		> div:first-child {
			flex-basis: calc(100% - (#{mixins.grid-unit(3, true)}));
		}

		> div:last-child {
			--form-spacing: 0.25rem;
			position: relative;
			flex: 1;
			margin-top: 2.5rem;
		}
	}

	&__double-field {
		display: flex;
		gap: 1rem;

		> .form-field + .form-field {
			margin-top: 0 !important;
		}
	}

	&__remove-button,
	&__add-button {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		cursor: pointer;

		svg {
			height: 20px;
		}
	}

	&__remove-button {
		justify-content: flex-end;
		margin-top: var(--p-rhythm);
	}

	&__delete-checkbox {
		display: none;
	}
}

@include mixins.from-md {
	.product-formset {
		&__columns {
			display: flex;
			gap: 3.75rem;
			margin-bottom: 2.5rem;
		}

		&__remove-button {
			position: absolute;
			bottom: 0;
			right: 0;
		}
	}
}
