@use "global/bases";

.licensee-page {
	&__title {
		@include bases.base-heading;
		font-size: var(--fs-h3);
		margin-bottom: 1rem;
	}
	&__cta {
		margin-top: var(--p-rhythm);
	}
}
