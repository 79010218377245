@use "mixins";
@use "global/bases";

.richtext-over-image {
	&__copy {
		&-super-title {
			@include bases.base-bold-sans;
			border-bottom: var(--border);
			border-bottom-color: currentColor;
			display: inline-block;
			width: auto;
			margin-bottom: var(--p-rhythm);
		}
		&-title {
			@include bases.base-heading;
			font-size: var(--fs-h2);
			margin-bottom: var(--p-rhythm);
		}
		.btn {
			margin-top: var(--p-rhythm);
			width: auto;
			display: inline-block;
		}
		.link-list {
			margin-top: var(--p-rhythm-sm);
		}
	}

	&--dark-background &__copy {
		background: var(--t-page-bg-inverted);
		color: var(--t-text-color-inverted);
	}
}

@include mixins.to-md {
	.richtext-over-image {
		display: flex;
		flex-direction: column-reverse;

		&__copy {
			margin-top: var(--p-rhythm);
		}

		&--dark-background &__copy {
			margin-top: 0;
			padding-top: calc(var(--p-rhythm) * 2);
			padding-bottom: calc(var(--p-rhythm) * 2);
			padding-left: var(--p-rhythm);
			padding-right: var(--p-rhythm);
		}
	}
}

@include mixins.from-md {
	.richtext-over-image {
		position: relative;
		&__copy {
			position: relative;
			z-index: 2;
			background: white;
			width: #{mixins.grid-unit(6, true)};
			margin-left: #{mixins.grid-unit(1, true)};
			padding: calc(2 * var(--p-rhythm-lg)) #{mixins.grid-unit(1, true)};
		}
		&__cover {
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 1;
			picture {
				height: 100%;
				img {
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}
}
