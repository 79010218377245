.search-result {
	display: grid;
	grid-template-areas: "t m";
	grid-template-columns: 1fr 1fr;
	border-top: 2px solid black;
	border-bottom: 2px solid black;

	&__text {
		grid-area: t;
		padding: var(--p-rhythm);
		padding-left: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;

		& > h4 {
			margin-bottom: var(--p-rhythm);
		}
	}

	&__media {
		grid-area: m;
		width: 100%;
		height: auto;
	}

	& + & {
		margin-top: var(--p-rhythm);
	}
}
