@use "mixins";

.checkout {
	max-width: var(--max-content-width);
	margin: 0 auto;

	&__banner {
		padding: var(--p-rhythm) 0;
		border-bottom: 2px solid black;
	}

	&__title {
		font-family: inherit;
		font-size: var(--fs-h4);
		letter-spacing: 2px;
		text-transform: uppercase;
		font-weight: bold;
	}

	&__subtitle {
		font-family: inherit;
		font-size: var(--fs-h4);
		font-weight: bold;
		margin: var(--p-rhythm) 0;
	}

	&__error {
		color: red;
	}

	&__continue-shopping {
		text-decoration: underline;
		font-weight: 400;
		text-underline-offset: 4px;
		margin-top: var(--p-rhythm);
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: calc(var(--p-rhythm-lg) * 2);
		justify-content: space-between;
		padding-top: var(--p-rhythm);
	}

	&__sidebar {
		background: #f3f3f3;
		padding: var(--p-rhythm);
		min-width: 280px;
	}

	&__coupon-btn {
		color: white;
		background: var(--store-darker-grey);
		padding: calc(var(--p-rhythm-sm) / 2);
		width: 100%;
		margin-top: var(--p-rhythm-sm);
		margin-bottom: var(--p-rhythm);
		text-align: center;
		&:hover {
			background: var(--store-dark-grey);
			cursor: pointer;
		}
	}

	&__coupon-error {
		margin-top: var(--p-rhythm-sm);
	}

	&__total {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: var(--p-rhythm) 0;
		font-weight: bold;
	}

	&__tabs {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: var(--p-rhythm-lg);
		margin-top: var(--p-rhythm-sm);
	}

	&__tab {
		color: var(--store-dark-grey);
		font-size: var(--fs-h3);
		transition: color 150ms ease-in-out;
		&--active {
			color: black;
		}
		&--complete {
			&:hover {
				cursor: pointer;
				color: black;
			}
		}
		&--divider {
			width: 2px;
			height: var(--fs-h3);
			background: black;
			display: inline-block;
		}
	}

	&__order-summary {
		font-size: var(--fs-h3);
		padding-bottom: var(--p-rhythm-sm);
		border-bottom: 1px solid var(--store-dark-grey);
		margin-bottom: var(--p-rhythm);
	}

	&__subtotal {
		display: flex;
		justify-content: space-between;
		margin-bottom: var(--p-rhythm-sm);
	}

	&__postage {
		margin-bottom: var(--p-rhythm);
		display: flex;
		flex-direction: column;
		gap: var(--p-rhythm-sm);
	}

	&__delivery {
		display: flex;
		justify-content: space-between;
		margin-top: calc(var(--p-rhythm-sm) / 2);
		color: var(--store-dark-grey);
		font-size: smaller;
		gap: var(--p-rhythm);
		& span {
			color: black;
			font-size: var(--fs-body);
			white-space: nowrap;
		}
	}

	&__items {
		& > *:not(:first-child) {
			border-top: 1px solid var(--store-dark-grey);
			padding-top: var(--p-rhythm);
		}
	}

	&-button {
		width: 100%;
		letter-spacing: normal;
	}

	&__empty {
		margin-top: var(--p-rhythm);
	}

	&__delivery-warning {
		margin: var(--p-rhythm) 0;
	}

	& .form-field {
		&__label {
			font-size: var(--fs-body);
			margin-top: var(--p-rhythm-sm);
			margin-bottom: 0;
			font-family: inherit;
			font-weight: 300;
			line-height: 2;
		}

		&__field {
			padding: calc(var(--p-rhythm-sm) / 2);
			background-color: #f3f3f3;
			border: none;
			font-weight: 300;
			letter-spacing: normal;
			text-transform: none;

			&--white {
				background-color: white;
			}
		}
	}

	&__shipping-methods {
		display: flex;
		gap: 0, 7em;
		flex-direction: column;
		margin-bottom: var(--p-rhythm);

		label {
			display: flex;
			align-items: center;
		}
		& input[type="radio"] {
			margin-right: 0.5em;
		}
	}

	&__overlay {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: #f3f3f3;
		opacity: 0.8;
		z-index: 1000;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: wait;
		pointer-events: none;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;

		&.is-active {
			pointer-events: all;
			position: fixed;
			opacity: 0.85;
		}

		&-content {
			text-align: center;
			font-weight: bold;
			& p {
				font-size: var(--fs-h3);
			}
		}
	}
}

@include mixins.from-md {
	.checkout {
		&__content {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
		}
		&__main {
			grid-column: span 2;
		}
		&__sidebar {
			padding: var(--p-rhythm-sm);
			min-width: auto;
		}
	}
}
