@use "mixins";
@use "constants";

.l-page-fold {
	position: relative;
	&__scroll-icon {
		z-index: 100;
		position: absolute;
		bottom: var(--p-rhythm);
		left: var(--p-rhythm);
		width: 17.5px;
		height: 28.5px;
		color: white;
		@include mixins.to-md {
			display: none;
		}
	}

	--video-position-x: center;
	--video-position-y: center;
	.credited-media__asset video {
		object-position: var(--video-position-x) var(--video-position-y);
	}
}

@include mixins.from-md {
	.l-page-fold {
		.content-cta__copy {
			padding: #{mixins.grid-unit(0.3, true)} #{mixins.grid-unit(1, true)};
		}
	}
}
