@use "mixins";
@use "global/bases";

.multi-download {
	&__title {
		font-size: var(--fs-h2);
	}

	.btn {
		display: inline-flex;
		width: auto;
		align-items: center;
		margin: var(--p-rhythm-lg) 0;
	}

	&__main-icon {
		width: 28px;
		height: auto;
		margin-left: 1rem;
	}

	&__subheading {
		font-size: var(--fs-h3);
		margin-bottom: var(--p-rhythm-sm);
	}

	&__list {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: var(--p-rhythm-sm);

		@include mixins.from-sm {
			grid-template-columns: repeat(2, 1fr);
		}
		@include mixins.from-md {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	&__link {
		@include bases.base-link;
	}
}
