@use "mixins";

.licensee-results {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: var(--p-rhythm-lg);

	.card {
		display: grid;
		grid-gap: var(--p-rhythm);

		&__details {
			border: none;
			padding: 0;
		}
	}
}

@include mixins.from-md {
	.licensee-results {
		grid-template-columns: repeat(3, 1fr);
	}
}

@include mixins.to-md {
	.licensee-results .card {
		grid-template-columns: repeat(2, 1fr);
	}
}
