@use "constants";
@use "mixins";
@use "global/bases";

.l-prose-blocks {
	$c: &;
	padding: calc(var(--p-rhythm-lg) * 2) var(--grid-gutter);
	max-width: var(--max-content-width);
	margin: 0 auto;
	&__super-title {
		@include bases.base-bold-sans;
		border-bottom: var(--border);
		border-bottom-color: currentColor;
		margin-bottom: var(--p-rhythm);
		display: inline-block;
		width: auto;
	}
	&__title {
		@include bases.base-heading;
		font-size: var(--fs-h1);
		margin-bottom: var(--p-rhythm);
	}

	> *:not(#{$c}__ignore) + * {
		margin-top: calc(var(--p-rhythm-lg) * 2);
	}

	&--tight {
		> *:not(#{$c}__ignore) + * {
			margin-top: var(--p-rhythm-lg);
		}
	}

	&__block--crt-dark {
		background: var(--t-page-bg-inverted);
		color: var(--t-text-color-inverted);
		padding-top: 4rem;
		padding-bottom: 4rem;

		a#{constants.$ext-link-query}:after {
			background-image: url("../images/external-link-white.svg") !important;
		}

		a#{constants.$dl-query}:after {
			background-image: url("../images/download-white.svg") !important;
		}
	}

	&__horizontal-rule {
		border: none;
		border-top: 1px solid var(--t-text-color);
	}

	&__block--store {
		transform: translateY(0) !important;
	}
}

@include mixins.to-md {
	.l-prose-blocks {
		&__block--crt-dark {
			padding-left: var(--p-rhythm-lg);
			padding-right: var(--p-rhythm-lg);
			padding-top: 2rem;
			padding-bottom: 2rem;
		}
	}
}

@include mixins.from-md {
	.l-prose-blocks {
		padding: #{mixins.grid-unit(1, true)} 0;
		&__block {
			padding-right: #{mixins.grid-unit(1, true)};
			padding-left: #{mixins.grid-unit(1, true)};
			&--tight {
				padding-left: 0;
				padding-right: 0;
			}
			&--large-gutter {
				padding-right: #{mixins.grid-unit(2, true)};
				padding-left: #{mixins.grid-unit(2, true)};
			}
			&--reduced-gutter {
				margin-right: calc(var(--p-rhythm-lg) * -1);
				margin-left: calc(var(--p-rhythm-lg) * -1);
			}
		}
		&__title {
			margin-bottom: var(--p-rhythm-lg);
		}

		&__block--crt-standard {
			padding-right: #{mixins.grid-unit(2, true)};
			padding-left: #{mixins.grid-unit(2, true)};
		}
		&__block--crt-standard.l-prose-blocks__block--crt-dark {
			margin-right: #{mixins.grid-unit(1, true)};
			margin-left: #{mixins.grid-unit(1, true)};
			padding-right: #{mixins.grid-unit(1, false)};
			padding-left: #{mixins.grid-unit(1, false)};
		}
		&__block--crt-maximum {
			border-top: 1px solid black;
			padding-top: 8rem;
			padding-bottom: 8rem;

			& + & {
				margin-top: 0;
			}
		}

		&__block--crt-columns-double .rich-text {
			column-count: 2;
			column-gap: var(--p-rhythm);
		}

		&__block--crt-maximum.l-prose-blocks__block--crt-dark {
			padding-top: 4rem;
			padding-bottom: 4rem;
		}

		&__horizontal-rule--standard {
			margin-right: #{mixins.grid-unit(1, true)};
			margin-left: #{mixins.grid-unit(1, true)};
		}

		&__horizontal-rule--maximum {
			// no margins, full bleed
		}
	}
}
