@use "global/bases";

.browser-detect {
	padding: var(--p-rhythm);

	&--button {
		@include bases.base-bold-sans;
		margin-top: 1em;
		padding: 0.2em 0.5em;
		background-color: var(--t-accent-colour);
		color: white;
		border: 1px solid black;
		display: inline-block;
		width: auto;

		&:hover {
			background-color: white;
			color: black;
		}
	}
}
