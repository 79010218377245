@use "constants";
@use "mixins";

.l-site-header {
	position: relative;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	background: var(--t-accent-colour);
	color: white;
	height: var(--header-height);
	padding-left: var(--grid-gutter);
	padding-right: 20px;

	max-width: var(--page-width);
	margin: 0 auto;

	&__logo {
		display: inline-block;
		width: 120px;
		margin-right: auto;
		img {
			width: auto;
			height: 100%;
		}
	}

	&__icon-link {
		transition: 250ms ease-in-out;
		border: 1px solid var(--t-text-color-inverted);
		color: var(--t-text-color-inverted);
		padding: 8px;
		width: 40px;
		height: 40px;
		flex-shrink: 0;

		svg {
			width: 100%;
			height: auto;
			display: block;
		}

		&.is-open,
		&:hover,
		&:active {
			cursor: pointer;
			color: var(--t-accent-colour);
			background: var(--t-text-color-inverted);

			&:after {
				background: var(--t-text-color) !important;
			}
		}

		&--cart {
			padding: 6px;
		}

		position: relative;
		.active-icon,
		.inactive-icon {
			transition: 150ms ease-in-out;
		}
		.active-icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 22px;
			height: 22px;

			opacity: 0;
		}

		.menu-open &[data-navigation-trigger],
		.search-open &[data-search-opener] {
			color: var(--t-accent-colour);
			background: var(--t-text-color-inverted);
			.active-icon {
				opacity: 1;
			}
			.inactive-icon {
				opacity: 0;
			}
		}

		&.has-items {
			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 8px;
				right: 6px;
				width: 9px;
				height: 9px;
				border-radius: 50%;
				background: var(--t-text-color-inverted);
				transition: background 250ms ease-in-out;
			}
		}
	}

	&__featured-button {
		display: none !important;

		&:hover {
			color: var(--t-accent-colour);

			&::after {
				color: var(--t-accent-colour);
			}
		}
	}

	&__buttons {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	.l-navigation {
		z-index: 2;
	}
}

@include mixins.from-sm {
	.l-site-header {
		&__featured-button {
			display: inline-flex !important;
		}
	}
}

@include mixins.from-md {
	.l-site-header {
		&__logo {
			width: 200px;
			img {
				width: 100%;
				height: auto;
			}
		}
	}
}

@include mixins.from-lg {
	.l-site-header {
		padding: var(--grid-gutter);
		justify-content: space-between;

		& * {
			font-size: 16px;
		}

		&__logo {
			width: 200px;
			margin-left: var(--p-rhythm);
			margin-right: 0;
		}

		&__icon-link {
			&--menu {
				display: none;
			}
		}

		.btn {
			margin-left: 10px;
		}
		&__logo {
			margin-right: var(--p-rhythm-sm);
		}
		.l-navigation {
			margin: 0 10px 0 var(--p-rhythm-sm);
		}

		&__menu-trigger {
			display: none;
		}
	}
}

@include mixins.from(1440px) {
	.l-site-header {
		&__logo {
			margin-left: var(--p-rhythm);
			margin-right: 0;
			width: 250px;
		}
		.l-navigation {
			margin: 0 10px 0 var(--p-rhythm-sm);
		}
	}
}

@include mixins.to(1500px) {
	.l-site-header {
		.btn {
			padding-left: var(--p-rhythm-sm);
			padding-right: var(--p-rhythm-sm);
		}
	}
}

@include mixins.from(1500px) {
	.l-site-header {
		.l-navigation {
			margin: 0 10px 0 var(--p-rhythm);
		}
	}
}

body.little {
	// Gross, blame Kim
	& .l-site-header {
		&__featured-button {
			&:hover:after {
				background-image: url("../images/external-link-teal.svg") !important;
			}
		}
	}
}

body.wordmark {
	& .l-site-header {
		background: var(--t-accent-colour-inverted);
		color: var(--t-accent-colour);

		&__logo {
			margin-left: 0;
			padding: var(--p-rhythm);
			background: var(--t-accent-colour);
		}

		&__icon-link {
			border: 1px solid var(--t-accent-colour);
			color: var(--t-accent-colour);

			&.is-open,
			&:hover,
			&:active {
				cursor: pointer;
				color: var(--t-accent-colour-inverted);
				background: var(--t-text-color);

				&:after {
					background: var(--t-text-color-inverted) !important;
				}
			}
		}

		&__featured-button {
			&:hover {
				color: var(--t-accent-colour-inverted);

				&::after {
					color: var(--t-accent-colour-inverted);
				}
			}
		}

		.submenu__links {
			background: var(--t-accent-colour-inverted);
		}
	}
}
