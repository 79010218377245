:root{--t-text-color: #000000;--t-text-color-inverted: #ffffff;--t-page-bg-color: #ffffff;--t-page-bg-inverted: #000000;--t-accent-colour: #000000;--t-accent-colour_RGB: 0, 0, 0;--t-accent-colour-light: #000000;--t-accent-colour-inverted: #ffffff;--store-dark-grey: #878787;--store-darker-grey: #4d4d4d;--border: 1px solid black;--border: 0.0625rem solid black;--p-rhythm: 20px;--p-rhythm: 1.25rem;--p-rhythm-sm: calc(var(--p-rhythm) / 2);--p-rhythm-lg: calc(var(--p-rhythm) * 2);--grid-gutter: 35px;--grid-gutter: 2.1875rem;--header-height: 60px;--header-height: 3.75rem;--max-content-width: 1440px;--max-content-width: 90rem;--mobile-bp: 768px;--mobile-bp: 48rem;--footer-logo-height: 18px;--footer-logo-height: 1.125rem;--footer-logo-width: 120px;--footer-logo-width: 7.5rem;--fs-h1:clamp(
		2.5rem,
		2rem + " + " + 2.5vw,
		5rem
	);--fs-h2:clamp(
		1.875rem,
		1.5rem + " + " + 1.875vw,
		3.75rem
	);--fs-h3:clamp(
		1.25rem,
		1.125rem + " + " + 0.625vw,
		1.875rem
	);--fs-body-big:clamp(
		1.75rem,
		1.75rem + " + " + 0vw,
		1.75rem
	);--fs-body-lg:clamp(
		1.125rem,
		1.125rem + " + " + 0vw,
		1.125rem
	);--fs-body:clamp(
		1.125rem,
		1.125rem + " + " + 0vw,
		1.125rem
	);--fs-body-sm:clamp(
		0.8125rem,
		0.8125rem + " + " + 0vw,
		0.8125rem
	);--fs-footer-menu:clamp(
		2.8125rem,
		2.8125rem + " + " + 0vw,
		2.8125rem
	);--fs-header-menu:clamp(
		0.875rem,
		0.875rem + " + " + 0vw,
		0.875rem
	)}:export{--fs-h1_min-width:320;--fs-h1_max-width:1920;--fs-h1_min-fs:40;--fs-h1_max-fs:80}:export{--fs-h2_min-width:320;--fs-h2_max-width:1920;--fs-h2_min-fs:30;--fs-h2_max-fs:60}:export{--fs-h3_min-width:320;--fs-h3_max-width:1920;--fs-h3_min-fs:20;--fs-h3_max-fs:30}:export{--fs-body-big_min-width:320;--fs-body-big_max-width:1920;--fs-body-big_min-fs:28;--fs-body-big_max-fs:28}:export{--fs-body-lg_min-width:320;--fs-body-lg_max-width:1920;--fs-body-lg_min-fs:18;--fs-body-lg_max-fs:18}:export{--fs-body_min-width:320;--fs-body_max-width:1920;--fs-body_min-fs:18;--fs-body_max-fs:18}:export{--fs-body-sm_min-width:320;--fs-body-sm_max-width:1920;--fs-body-sm_min-fs:13;--fs-body-sm_max-fs:13}:export{--fs-footer-menu_min-width:320;--fs-footer-menu_max-width:1920;--fs-footer-menu_min-fs:45;--fs-footer-menu_max-fs:45}:export{--fs-header-menu_min-width:320;--fs-header-menu_max-width:1920;--fs-header-menu_min-fs:14;--fs-header-menu_max-fs:14}@media screen and (min-width: 64rem){:root{--p-rhythm: 36px;--p-rhythm: 2.25rem;--grid-gutter: 20px;--grid-gutter: 1.25rem;--grid-col: min(8.47vw, 122px);--grid-col: min(8.47vw, 7.625rem);--header-height: 120px;--header-height: 7.5rem}}body.little{--t-accent-colour: #146075;--t-accent-colour_RGB: 20, 96, 117;--t-accent-colour-light: #e4f4f9;--footer-logo-height: 50px;--footer-logo-height: 3.125rem;--footer-logo-width: 300px;--footer-logo-width: 18.75rem}