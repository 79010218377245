@use "mixins";

@use "global/bases";

.btn {
	@include bases.base-bold-sans;
	transition: 250ms ease-in-out;
	color: var(--t-text-color);
	border: 1px solid var(--t-text-color);
	padding: 11px 20px;
	width: auto;
	letter-spacing: 2px;
	font-weight: bold;

	&:hover,
	&:focus,
	&:active {
		cursor: pointer;
		color: var(--t-text-color-inverted);
		background: var(--t-text-color);
	}

	&--invert {
		border: 1px solid var(--t-text-color-inverted);
		color: var(--t-text-color-inverted);
		&:hover,
		&:focus,
		&:active {
			color: var(--t-text-color);
			background: var(--t-text-color-inverted);
		}
	}

	&--dark {
		background: var(--t-page-bg-inverted);
		border: 1px solid var(--t-page-bg-inverted);
		color: var(--t-text-color-inverted);
		&:hover,
		&:focus,
		&:active {
			color: var(--t-text-color);
			background: var(--t-text-color-inverted);
		}
	}

	&--store {
		text-align: center;
		text-transform: none;
		font-weight: 400;
		outline: black;
	}

	&--w-max {
		width: max-content;
	}

	&--cart-continue,
	&--cart-checkout {
		width: 100%;
		border: 2px solid white;

		& + & {
			margin-top: 1rem;
		}
	}

	&--big {
		font-size: var(--fs-body-big);
	}

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	@include bases.ext-link-icon {
		display: inline-flex;
		align-items: center;

		&:hover,
		&:focus,
		&:active {
			&:after {
				background-image: url("../images/external-link-white.svg");
			}
		}
		&.btn--hide-icon {
			&:after {
				content: none;
			}
		}
		&.btn--dark,
		&.btn--invert {
			&:after {
				background-image: url("../images/external-link-white.svg");
			}
			&:hover,
			&:focus,
			&:active {
				&:after {
					background-image: url("../images/external-link-black.svg");
				}
			}
		}
	}
}

.btn-list {
	.btn {
		display: inline-block;
	}
	.btn + .btn {
		margin-top: var(--p-rhythm-sm);
	}
}

.btn-group {
	display: flex;
	justify-content: center;
	gap: var(--p-rhythm-sm);

	&--left-align {
		justify-content: start;
	}
}

@include mixins.from-md {
	.btn-list {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		.btn + .btn {
			margin-top: var(--p-rhythm-sm);
		}
	}

	.btn--cart-continue {
		display: none;
	}
}
