@use "constants";
@use "mixins";

@use "global/bases";

.link-list {
	$c: &;
	li + li {
		margin-top: 0.5em;
	}
	a {
		@include bases.base-link;
		width: auto;
		line-height: 1.2;
	}

	&--bordered {
		position: relative;
		li {
			border-left: var(--border);
			padding: 0.3em 0 0.5em 0;
			padding-left: 20px;
		}
		li + li {
			margin-top: 0;
		}
	}

	&--invert {
		color: white;
		&#{$c}--bordered {
			li {
				border-left-color: white;
			}
		}
		a {
			@include bases.ext-link-icon {
				&:after {
					background-image: url("../images/external-link-white.svg");
					transform: translateY(2px);
				}
			}
		}
	}
}

@include mixins.from-md {
	.link-list {
		&--3x {
			columns: 3;
		}
		&--2x {
			columns: 2;
			li {
				-webkit-column-break-inside: avoid;
				break-inside: avoid;
				width: 100%;
			}
		}
	}
}
