@use "mixins";

@use "global/bases";

.accordion {
	&__sections {
		border-top: 1px solid var(--t-text-color);
		border-bottom: 1px solid var(--t-text-color);
		list-style: none;
	}

	&__section {
		&__head {
			display: flex;
			position: relative;
			padding-right: 3rem;

			&:after {
				position: absolute;
				right: 1.1rem;
				content: "";
				width: 1.3em;
				top: 1.15rem;
				min-width: 1.3em;
				height: 1.3em;
				background-size: contain;
				background-repeat: no-repeat;
				display: inline-block;
				vertical-align: bottom;
				transition: background-image 500ms ease-in-out;
				background-image: url("../images/accordion-plus.svg");
			}
		}

		&.is-open &__head:after {
			background-image: url("../images/accordion-minus.svg");
		}

		&.is-animating {
			transition: height 0.5s ease;
		}

		&__index {
			font-family: "Blacker Display Regular";
			line-height: 1;
			padding-right: 1rem;
			width: 3ch;
		}

		&__index,
		&__title {
			padding-top: 1.4rem;
			padding-bottom: 1.3rem;
			font-size: var(--fs-h3);
		}

		&__body {
			padding-bottom: 2rem;
		}

		& + & {
			border-top: 1px solid var(--t-text-color);
		}
	}
}

@include mixins.from-md {
	.accordion {
		&__sections {
			padding-left: #{mixins.grid-unit(1, true)};
		}

		&__section {
			&__index {
				padding-right: 2rem;
			}

			&__head:after {
				top: 1.3rem;
			}
		}
	}
}
