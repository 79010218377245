@use "global/bases";
@use "constants";

$control-size: 1em;

.form-boolean {
	input {
		display: none;
	}
	&__label {
		font-family: constants.$sans-font;
		font-weight: bold;
		display: block;
		text-transform: initial;
		position: relative;
		padding-left: 2 * $control-size;
		&:before,
		&:after {
			content: "";
			width: $control-size;
			height: $control-size;
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -#{$control-size/2};
		}
		&:before {
			border: 1px solid;
		}
		&:after {
			transition: transform 200ms ease-in-out;
			background: black;
			transform: scale(0);
		}
		&:hover {
			cursor: pointer;
			&:after {
				transform: scale(0.5);
			}
		}
	}
	input[type="radio"] + &__label {
		&:before,
		&:after {
			border-radius: 100%;
		}
	}
	input:checked + &__label {
		&:after {
			transform: scale(0.7);
		}
	}
}
