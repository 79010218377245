@use "mixins";
@use "global/bases";

.products-heading {
	text-transform: uppercase;
	letter-spacing: 2px;

	&--landing {
		border-top: 2px solid black;
		border-bottom: none;
	}

	&__title {
		font-weight: bold;
		text-decoration: underline;
		text-underline-offset: 4px;
		padding: var(--p-rhythm) 0;
	}

	&__links {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		gap: var(--p-rhythm-sm);
		border-bottom: 2px solid black;
		padding: var(--p-rhythm) 0;

		& a {
			@include bases.base-link;
			text-decoration: none;
			white-space: nowrap;
			&:hover,
			&:focus,
			&:active,
			&.active {
				text-decoration: underline;
			}
		}
	}
}

@include mixins.from-sm {
	.products-heading {
		&__links {
			flex-direction: row;
			gap: 1rem var(--p-rhythm);
		}
	}
}
