@use "constants";
@use "mixins";
@use "global/bases";
@use "variables";

// This thing is a fucking mess, it services too many components and changing one of them breaks others, it should be broken into
// separate components based on location and content type (page header, streamfield block with video, sf with image etc)
.content-cta {
	$c: &;
	&__copy {
		&-super-title {
			@include bases.base-bold-sans;
			border-bottom: var(--border);
			border-bottom-color: currentColor;
			display: inline-block;
			width: auto;
		}
		&-title {
			font-size: var(--fs-h2);
		}
		&-text {
			margin-top: var(--p-rhythm);
			margin-bottom: var(--p-rhythm);
		}

		&-blockquote {
			padding: var(--p-rhythm) 0;
			margin: 0;
			&:last-child {
				padding-bottom: 0;
			}
			&-text {
				@include bases.base-heading;
				line-height: 1.1;
				font-weight: normal;
				font-size: var(--fs-h3);
			}
			&-name {
				@include bases.base-bold-sans;
				margin-top: var(--p-rhythm-sm);
			}
			&-job-title {
			}
		}
		.btn + &-blockquote,
		.link-list + &-blockquote {
			border-top: var(--border);
			margin-top: var(--p-rhythm);
		}
	}
	&__cover {
		&-video {
			display: block;
			height: auto;
			width: 100%;
			backface-visibility: hidden;

			video {
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: center left;
			}
		}
	}
	.btn {
		display: inline-block;
		width: auto;
		&:last-child {
			margin-top: var(--p-rhythm);
		}
	}

	&--full-page {
		#{$c}__copy {
			padding: calc(var(--p-rhythm-lg) * 2) var(--grid-gutter);
			border: 0 none;
			&-title {
				font-size: var(--fs-h1);
			}
		}
	}

	&--simple {
		display: block;
		background-color: var(--t-accent-colour-light);
		#{$c}__copy {
			color: white;
			background: var(--t-accent-colour);
		}
	}

	&--featured {
		#{$c}__copy {
			color: white;
			background: var(--t-accent-colour);
			position: relative;
			z-index: 2;
			min-height: 0;
		}
	}

	&--dark-background {
		background: var(--t-page-bg-inverted);
		color: var(--t-text-color-inverted);
	}
}

@include mixins.to-md {
	.content-cta {
		$c: &;
		display: flex;
		flex-direction: column-reverse;
		&__copy {
			padding-left: var(--p-rhythm);
			padding-top: var(--p-rhythm);
			border-left: var(--border);
			&-title {
				margin: var(--p-rhythm) 0;
				&:first-child {
					margin-top: 0;
				}
			}
		}

		&__cover-video {
			background-color: white;
		}

		&--with-cover-video &__cover {
			position: relative;
			padding-bottom: 57.1%;

			.credited-media {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
			}
		}

		&--dark-background {
			#{$c}__copy {
				padding-left: var(--p-rhythm);
				padding-right: var(--p-rhythm);
				padding-bottom: var(--p-rhythm);
			}

			.btn-list .btn {
				color: var(--t-text-color-inverted);
				border-color: var(--t-text-color-inverted);
			}
			.btn-list .btn#{constants.$ext-link-query}:after {
				background-image: url("../images/external-link-white.svg");
			}
		}
	}
}

@include mixins.from-md {
	.content-cta {
		$c: &;
		display: grid;
		grid-template-areas: "copy cover";
		grid-template-columns: 1fr 1fr;
		&__copy {
			padding: var(--p-rhythm) 0;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			grid-area: copy;
			padding-right: 25%;
			border-top: var(--border);
			border-bottom: var(--border);
			&-super-title {
				margin-bottom: var(--p-rhythm);
			}
			&-text {
				margin-top: var(--p-rhythm-sm);
				margin-bottom: var(--p-rhythm);
			}
		}
		&__cover {
			position: relative;
			grid-area: cover;
			&-video {
				display: block;
				position: absolute;
				z-index: 1;
				height: 100%;
				width: 100%;
				backface-visibility: hidden;

				video {
					height: 100%;
					width: 100%;
					object-fit: cover;
					object-position: center left;
				}

				.credited-media__attribution {
					right: 1rem;
					bottom: 4rem;
				}

				.video-player {
					height: 100%;
					max-width: 100%;
					width: 100%;

					video {
						object-position: center;
					}
				}

				.video-player .plyr__controls {
					padding-bottom: 1rem;
					margin-left: 1rem;
					margin-right: 1rem;
				}
			}
			picture {
				display: block;
				height: 100%;
				img {
					height: 100%;
					object-fit: cover;
				}
			}
		}

		&--with-cover-video &__cover {
			.credited-media {
				top: 0;
			}
		}

		&--full-page {
			#{$c}__copy {
				padding: #{mixins.grid-unit(1, true)};
			}
		}

		&--simple {
			display: block;
			#{$c}__copy {
				padding-right: #{mixins.grid-unit(3, false)};
			}
		}

		&--reverse {
			#{$c}__copy {
				grid-column: 2;
			}
			#{$c}__cover {
				grid-column: 1;
			}
		}

		&--home {
			#{$c}__copy {
				justify-content: center;
			}

			#{$c}__cover {
				img {
					position: absolute;
				}
			}
		}

		&--dark-background {
			#{$c}__copy {
				padding-left: var(--p-rhythm);
				padding-right: var(--p-rhythm);
			}
		}

		&--featured {
			min-height: calc(100vh - var(--header-height));
			position: relative;
			max-height: 900px;
			&-half {
				min-height: calc(65vh - var(--header-height));
			}
			#{$c}__copy {
				min-height: 0;
				background: rgba(var(--t-accent-colour_RGB), 0.6);
				opacity: 0.6;
				justify-content: center;
			}
			#{$c}__cover {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				grid-column: 1/3;
			}
			#{$c}__copy-blockquote {
				border-top: 1px solid white;
				border-bottom: 1px solid white;
				padding: var(--p-rhythm) 0;
			}
			#{$c}__copy-blockquote + .btn {
				margin-top: var(--p-rhythm);
			}
		}
	}
}
