@use "constants";
@use "mixins";

@function strip-units($number) {
	// Strips units from string e.g. 15px -> 15
	@return $number / ($number * 0 + 1);
}

@mixin gen-clamp(
	$propName,
	$minFontSizePx,
	$maxFontSizePx,
	$minWidthPx: constants.$bp-tiny,
	$maxWidthPx: constants.$bp-very-large
) {
	$minWidth: strip-units($minWidthPx) / 16;
	$maxWidth: strip-units($maxWidthPx) / 16;
	$minFontSize: strip-units($minFontSizePx) / 16;
	$maxFontSize: strip-units($maxFontSizePx) / 16;

	$slope: ($maxFontSize - $minFontSize) / ($maxWidth - $minWidth);
	$yAxisIntersection: -$minWidth * $slope + $minFontSize;

	#{$propName}: clamp(
		#{$minFontSize * 1rem},
		#{($yAxisIntersection * 1rem)} + " + " + #{($slope * 100vw)},
		#{$maxFontSize * 1rem}
	);

	// If these varaible names change, update them in updateClampedCSS.js
	@at-root {
		:export {
			#{$propName}_min-width: #{strip-units($minWidthPx)};
			#{$propName}_max-width: #{strip-units($maxWidthPx)};
			#{$propName}_min-fs: #{strip-units($minFontSizePx)};
			#{$propName}_max-fs: #{strip-units($maxFontSizePx)};
		}
	}
}

:root {
	--t-text-color: #000000;
	--t-text-color-inverted: #ffffff;
	--t-page-bg-color: #ffffff;
	--t-page-bg-inverted: #000000;
	--t-accent-colour: #000000;
	--t-accent-colour_RGB: 0, 0, 0;
	--t-accent-colour-light: #000000; // currently only used in animations.scss
	--t-accent-colour-inverted: #ffffff;

	--store-dark-grey: #878787;
	--store-darker-grey: #4d4d4d;

	--border: 1px solid black;

	--p-rhythm: 20px;
	--p-rhythm-sm: calc(var(--p-rhythm) / 2);
	--p-rhythm-lg: calc(var(--p-rhythm) * 2);

	--grid-gutter: 35px;

	--header-height: 60px;

	--max-content-width: 1440px;

	--mobile-bp: #{constants.$bp-small};

	--footer-logo-height: 18px;
	--footer-logo-width: 120px;

	@include gen-clamp("--fs-h1", 40px, 80px);
	@include gen-clamp("--fs-h2", 30px, 60px);
	@include gen-clamp("--fs-h3", 20px, 30px);
	@include gen-clamp("--fs-body-big", 28px, 28px);
	@include gen-clamp("--fs-body-lg", 18px, 18px);
	@include gen-clamp("--fs-body", 18px, 18px);
	@include gen-clamp("--fs-body-sm", 13px, 13px);

	@include gen-clamp("--fs-footer-menu", 45px, 45px);
	@include gen-clamp("--fs-header-menu", 14px, 14px);
}

@include mixins.from-md {
	:root {
		--p-rhythm: 36px;

		--grid-gutter: 20px;
		--grid-col: min(8.47vw, 122px);

		--header-height: 120px;
	}
}

body.little {
	--t-accent-colour: #146075;
	--t-accent-colour_RGB: 20, 96, 117;
	--t-accent-colour-light: #e4f4f9;

	--footer-logo-height: 50px;
	--footer-logo-width: 300px;
}

// 80pt - --fs-h1 - Home section title, fold title, card list title,
// 60pt - --fs-h2 - The Story title, richtext media block, richtext h2,
// 45pt - Footer main links
// 30pt - --fs-h3 -   Footer secondary links, feat quote body, Card title, richtext h3,
// 18pt - --fs-body-lg - Breadcrumb super title, footer acknowledgement, fold supertitle
// 15pt - --fs-body - The Story body, Button text, Home section body, Home section links, feat quote name, card body/learn more, tile title,
// 14pt - Menu,
// 13pt - --fs-body-sm - Brand Tas/gov sign off, fold quote job title
