@use "global/bases";

.icon-bar {
	$c: &;
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	&__item {
		display: flex;
		justify-content: flex-end;
		&--spacer {
			margin-top: var(--p-rhythm);
		}
	}
	&__item + &__item {
		margin-left: 20px;
	}
	&__link {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: auto;
		&-icon {
			transition: 250ms ease-in-out;
			border: 1px solid var(--t-text-color-inverted);
			color: var(--t-text-color-inverted);
			padding: 8px;
			width: 40px;
			height: 40px;
			flex-shrink: 0;
			svg {
				width: 100%;
				height: auto;
				display: block;
			}
		}
		&-label {
			@include bases.base-bold-sans;
			color: white;
			margin-right: 20px;
		}

		&:hover,
		&:focus,
		&:active {
			cursor: pointer;
			#{$c}__link {
				&-icon {
					color: var(--t-accent-colour);
					background: var(--t-text-color-inverted);
				}
			}
		}
	}

	&--vertical {
		flex-direction: column;
		#{$c}__item + #{$c}__item:not(#{$c}__item--spacer) {
			margin-top: 10px;
			margin-left: 0;
		}
	}
}
