@use "mixins";

.l-products {
	&__grid {
		display: grid;
		gap: var(--p-rhythm);
		grid-template-columns: minmax(0, 1fr);
	}
}

@include mixins.from-sm {
	.l-products {
		&__grid {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
	}
}

@include mixins.from-md {
	.l-products {
		&__grid {
			gap: var(--p-rhythm-sm);
			grid-template-columns: repeat(3, minmax(0, 1fr));
			&--4 {
				grid-template-columns: repeat(4, minmax(0, 1fr));
			}
			&--products-page {
				& a {
					display: flex;
				}
			}
		}
	}
}
