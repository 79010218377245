@use "constants";
@use "mixins";
@use "global/bases";

.l-home-fold {
	height: 70vw;

	.video-player {
		height: 100%;
	}
}

@include mixins.from-md {
	.l-home-fold {
		height: calc(100vh - var(--header-height));

		.plyr {
			&__controls {
				padding-bottom: var(--p-rhythm-lg);
			}
		}
	}
}
