@use "constants";
@use "mixins";
@use "global/bases";

.licensed-products {
	border-bottom: var(--border);

	&__header {
		@include bases.base-bold-sans;
		text-align: center;
		font-weight: bold;
		display: grid;
		grid-template-columns: 40% 20% 20% 20%;
		border-top: var(--border);

		> div {
			padding: var(--p-rhythm-sm);

			&:not(:first-child) {
				border-left: var(--border);
			}
		}
	}

	&__row {
		display: grid;
		grid-template-columns: 40% 20% 20% 20%;
		border-top: var(--border);
		padding: var(--p-rhythm);

		> div {
			&:first-child {
				@include bases.heading-font;
				font-size: var(--fs-h3);
				letter-spacing: initial;
			}
		}

		&__stat {
			@include bases.base-bold-sans;
			display: flex;
			padding-top: calc(var(--p-rhythm-sm) * 0.6);

			img {
				max-width: 40px;
			}
		}
	}
}

@include mixins.to-lg() {
	.licensed-products {
		&__header {
			display: none;
		}

		&__row {
			display: flex;
			flex-direction: column;

			&__stat {
				img {
					max-width: calc(var(--p-rhythm-sm) * 1.5);
					margin-right: calc(var(--p-rhythm-sm) * 0.75);
				}

				div {
					font-size: var(--fs-body-sm);
				}
			}
		}
	}
}

@include mixins.from-lg() {
	.licensed-products {
		&__row {
			padding: 0;

			&__stat {
				justify-content: center;

				&-label {
					display: none;
				}
			}

			> div {
				padding: var(--p-rhythm-sm);

				&:not(:first-child) {
					border-left: var(--border);
				}
			}
		}
	}
}
