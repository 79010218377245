@use "mixins";

.l-scroll-tracker {
	$c: &;

	position: fixed;
	top: 50%;
	left: calc(var(--p-rhythm) + var(--grid-gutter));
	z-index: 100;

	width: #{mixins.grid-unit(1, true)};

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	&__pip:before,
	&__icon {
		--track-color-active: white;
		--track-color: rgba(255, 255, 255, 0.5);
		.t-dark & {
			--track-color-active: black;
			--track-color: rgba(0, 0, 0, 0.5);
		}
	}

	&__pip {
		position: relative;
		height: 18px;
		transition: height 540ms ease-in-out;
		&:before {
			content: "";
			height: 100%;
			width: 1px;
			position: absolute;
			left: 0;
			top: 0;
			background: var(--track-color);
			display: block;
		}
		&.is-active {
			height: 100px;
			&:before {
				background: var(--track-color-active);
				width: 3px;
				margin-left: -1px;
			}
		}

		&-label {
			white-space: nowrap;
			text-decoration: underline;
			padding-left: var(--p-rhythm-sm);

			transition: 200ms ease-in-out;
			opacity: 0;
		}

		&:hover {
			#{$c}__pip-label {
				text-decoration-thickness: 2px;
			}
		}
	}

	&__icon {
		margin-top: 20px;
		margin-left: -8px;
		width: 17.5px;
		height: 28.5px;
		color: var(--track-color-active);
	}

	&__pip + &__pip {
		margin-top: 10px;
	}

	> * {
		position: relative;
		z-index: 2;
	}

	&:after {
		content: "";
		height: 100vh;
		width: 500px;
		position: fixed;
		left: 0;
		top: 0;
		bottom: 0;
		background: white;
		box-shadow: 1px 0px 15px 2px rgba(0, 0, 0, 0.3);

		transition: 200ms ease-in-out;
		opacity: 0;
		pointer-events: none;
	}

	&:hover {
		#{$c}__pip:before,
		#{$c}__icon {
			--track-color-active: black;
			--track-color: black;
		}

		#{$c}__pip-label,
		&:after {
			opacity: 1;
		}
	}
}

@include mixins.to-md {
	.l-scroll-tracker {
		display: none;
	}
}
