.product-card {
	display: flex;
	flex-direction: column;
	width: 100%;
	&__title {
		margin-top: 0.5rem;
		font-size: var(--fs-body-lg);
		font-weight: bold;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	&__image {
		flex-grow: 1;
		position: relative;
		& img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__other-image {
		top: 0;
		left: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;

		&.is-active {
			opacity: 1;
		}
	}
	&__price {
		font-size: 14px;
	}
}
