@use "mixins";
@use "global/bases";

.licensee-title-block {
	padding-bottom: 0;
}

.licensee-filter {
	padding-top: 0;
	display: flex;
	gap: var(--p-rhythm);
	flex-direction: column;
}

.licensee-cta {
	&__super-title {
		@include bases.base-bold-sans;
		border-bottom: var(--border);
		border-bottom-color: currentColor;
		display: inline-block;
		width: auto;
		margin-bottom: 1rem;
	}
	&__title {
		font-size: var(--fs-h1);
	}
	&__copy {
		&-text {
			margin: var(--p-rhythm-lg) 0;
		}

		&-cta {
			width: auto;
			display: inline-block;
		}
	}
}

@include mixins.from-md {
	.licensee-filter {
		flex-direction: row;
	}

	.licensee-cta {
		&__copy {
			margin: 0 var(--p-rhythm-lg);
		}
	}
}
