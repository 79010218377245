@use "mixins";
@use "global/bases";

.l-navigation {
	&__item {
		display: block;
		line-height: 1;
		&-link {
			width: auto;
		}
	}
}

@include mixins.to-lg {
	.l-navigation {
		transition: transform 400ms ease-in-out;
		height: calc(100vh - var(--header-height));
		width: 100%;
		position: fixed;
		top: var(--header-height);
		left: 0;
		display: flex;
		flex-direction: column;
		overflow-y: scroll;
		background: var(--t-accent-colour);
		color: white;
		padding: var(--p-rhythm-lg) 20%;

		pointer-events: none;
		opacity: 0;

		&.is-active {
			opacity: 1;
			pointer-events: all;
		}
		&__item {
			position: relative;
			&-link {
				@include bases.base-heading;
				font-size: var(--fs-h2);
			}
		}

		&__item + &__item {
			margin-top: 0.8em;
		}

		.orientation-changing & {
			transition: none;
		}
	}
}

@include mixins.from-sm {
	.l-navigation {
		&__item {
			&--mobile {
				display: none;
			}
		}
	}
}

@include mixins.from-lg {
	.l-navigation {
		--pad: 15px;

		flex: 1;

		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;

		&__item {
			$ci: &;
			position: relative;
			margin: 0;

			&-link {
				@include bases.base-bold-sans;
				@include bases.underline-link();
				white-space: nowrap;
				font-weight: normal;

				&--current {
					&:after {
						width: 100%;
					}
				}
			}

			.submenu {
				transition: opacity 200ms ease-in-out;
				position: absolute;
				top: 85%;
				left: calc(-1 * var(--pad));
				min-width: 36ch;

				&__links {
					padding: var(--pad);
				}

				opacity: 0;
				pointer-events: none;
				&:hover,
				&:active,
				&:focus {
					.submenu {
						opacity: 1;
						pointer-events: all;
					}
				}
			}

			&:focus-within,
			&:hover,
			&:active,
			&:focus {
				.submenu {
					opacity: 1;
					pointer-events: all;
				}
			}
		}

		&__item + &__item {
			margin-left: var(--pad);
			&:last-child {
				margin-left: 0;
			}
		}
	}
}

@include mixins.from(1440px) {
	.l-navigation {
		--pad: 20px;
	}
}

body.wordmark .l-navigation {
	background: var(--t-accent-colour-inverted);
	color: var(--t-accent-colour);
}

@include mixins.to-lg() {
	body.wordmark .l-navigation {
		padding: var(--p-rhythm-lg) 10%;
	}
}
