@use "mixins";

.cart-dropdown {
	background: var(--t-accent-colour);
	color: white;
	position: absolute;
	right: 0;
	top: calc(var(--header-height) - 1px);
	z-index: 110;
	width: 100vw;
	padding: calc(var(--p-rhythm) * 3);
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	height: calc(100vh - var(--header-height) + 2px);
	overflow: auto;

	&.is-open {
		opacity: 1;
		visibility: visible;
		pointer-events: all;
	}

	&__banner {
		line-height: 1;
		text-transform: uppercase;
		letter-spacing: 2px;
		border-bottom: 2px solid white;
		margin-bottom: var(--p-rhythm);
		padding-bottom: var(--p-rhythm-sm);
		font-weight: bold;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}

	&__close {
		display: none;
		width: 3rem;
		aspect-ratio: 1/1;
		background-color: white;
		line-height: 0;
		cursor: pointer;
		padding: 5px;
		svg {
			background-color: white;
			stroke: white;
			stroke-width: 1px;
		}
	}
}

@include mixins.from-md {
	.cart-dropdown {
		width: 500px;
		padding: var(--p-rhythm);
		padding-top: 0;

		&__close {
			display: block;
		}
		&.is-open {
			height: auto;
		}
	}
}
