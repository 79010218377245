@font-face {
	font-family: "Moderat";
	src:
		url("../fonts/Moderat-Black.woff2") format("woff2"),
		url("../fonts/Moderat-Black.woff") format("woff");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Moderat";
	src:
		url("../fonts/Moderat-Light.woff2") format("woff2"),
		url("../fonts/Moderat-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Blacker Display Regular";
	src: url("../fonts/BlackerDisplay-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Moderat";
	src:
		url("../fonts/Moderat-Regular.woff2") format("woff2"),
		url("../fonts/Moderat-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Moderat";
	src:
		url("../fonts/Moderat-Bold.woff2") format("woff2"),
		url("../fonts/Moderat-Bold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
