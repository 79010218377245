.cm-form {
	&__title {
		font-size: var(--fs-h2);
	}

	&__paragraph {
		margin: var(--p-rhythm) 0;
	}

	&__errors {
		margin: var(--p-rhythm) 0;
		color: red;
	}
}
