@use "mixins";

@use "global/bases";

.step {
	position: relative;
	padding-left: calc(30px + var(--p-rhythm));
	&--index {
		@include bases.base-heading;
		border-top: var(--border);
		position: absolute;
		left: 0;
		top: 0;
		font-size: var(--fs-h2);
		padding-top: var(--p-rhythm-sm);
		width: 30px;
	}
	&--title {
		@include bases.base-heading;
		margin-top: -0.06em;
		font-size: var(--fs-h1);
		margin-bottom: var(--p-rhythm-sm);
	}
	&--copy {
	}
	.btn-group {
		margin-top: var(--p-rhythm);
	}
}

.step-list {
	list-style: none;
	.step + .step {
		margin-top: var(--p-rhythm-lg);
	}
}

@include mixins.from-md {
	.step {
		padding-left: #{mixins.grid-unit(1, true)};
		&--index {
			width: #{mixins.grid-unit(1, false)};
		}
		.btn-group {
			margin-top: var(--p-rhythm-sm);
		}
	}
}
