@use "mixins";

@include mixins.to-md {
	.l-listing-filter {
		padding: var(--grid-gutter) var(--p-rhythm-lg) 0;

		& > .form-field + .form-field {
			margin-top: var(--p-rhythm);
		}
	}
}

@include mixins.from-md {
	.l-listing-filter {
		padding: #{mixins.grid-unit(1, true)};
		padding-bottom: 0;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: var(--grid-gutter);
	}
}
