@use "mixins";

.dialog {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
	z-index: 1000;
	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba($color: black, $alpha: 0.3);
		width: 100vw;
		height: 100vh;
	}

	&__panel {
		margin: 0 auto;
		max-width: calc(100% - var(--p-rhythm-lg));
		background: white;
		padding: var(--p-rhythm-lg);
		position: relative;
	}

	&__close {
		position: absolute;
		padding: var(--p-rhythm);
		top: 0;
		right: 0;
		box-sizing: content-box;
		cursor: pointer;
		width: 1rem;
	}

	&__form,
	&__form .btn {
		margin-top: var(--p-rhythm);
	}

	&__form .form-field__field {
		font-weight: 300;
		letter-spacing: normal;
		text-transform: none;
	}
}

@include mixins.from-md {
	.dialog {
		&__panel {
			max-width: 60%;
		}
	}
}
