@use "global/bases";
@use "mixins";

.product-description {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-areas:
		"crumbs crumbs"
		"images title"
		"images price"
		"images description"
		"images selection"
		"images bulk"
		"images extra-info";
	gap: var(--p-rhythm) var(--p-rhythm-lg);

	&__background {
		background-color: #f3f3f3;
	}

	&__crumbs {
		grid-area: crumbs;
		list-style: none;
		text-transform: capitalize;
		display: flex;
		margin-bottom: var(--p-rhythm-lg);
		& li {
			display: flex;
			color: var(--store-dark-grey);
			&:last-child {
				color: black;
			}
		}
		& li:not(:first-child) {
			padding-left: var(--p-rhythm-sm);
			&::before {
				content: "|";
				padding-right: var(--p-rhythm-sm);
				color: var(--store-dark-grey);
			}
		}
	}

	&__images {
		grid-area: images;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: var(--p-rhythm-lg);
		& .main-image {
			position: relative;
			aspect-ratio: 1 /1;
			overflow: hidden;
			& [data-img-main] {
				opacity: 0;
				height: 0;
				visibility: hidden;
				pointer-events: none;
				transition: opacity 0.3s ease-in-out;
				&.active {
					height: auto;
					visibility: visible;
					display: block;
					opacity: 1;
					transition-delay: 150ms;
				}
			}
		}
		& .image-bank {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: var(--p-rhythm-sm);
			& > * {
				cursor: pointer;
			}
		}
	}

	&__title {
		grid-area: title;
		@include bases.base-bold-sans;
		letter-spacing: normal;
		font-size: var(--fs-h3);
	}

	&__price {
		grid-area: price;
		font-size: 1.4rem;
		margin-top: calc(var(--p-rhythm-sm) * -1);
	}

	&__description {
		grid-area: description;
	}

	&__selection {
		grid-area: selection;
		display: flex;
		flex-direction: column;
		margin: -1px; // annoying hack to align with button
		gap: 1px;
		height: fit-content;

		& > * {
			outline: 1px solid var(--store-dark-grey);
			padding: var(--p-rhythm-sm);
		}

		& .quantity-counter {
			display: flex;
			align-items: center;
			& input::-webkit-outer-spin-button,
			& input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
			& input[type="number"] {
				background-color: transparent;
				border: none;
				text-align: center;
				-moz-appearance: textfield;
			}

			& button {
				display: flex;
				align-items: center;
				cursor: pointer;
				& > svg {
					width: 12px;
					height: 18px;
				}
			}
		}

		& .selection {
			&__variation,
			&__quantity {
				display: flex;
				justify-content: space-between;
				gap: var(--p-rhythm-sm);
			}

			&__variation {
				display: flex;
				align-items: center;
				& select {
					font: inherit;
					font-size: var(--fs-body);
					padding: 0.4em;
					min-width: 200px;
					text-transform: none;
					letter-spacing: normal;
				}
			}
		}
	}

	&__bulk {
		grid-area: bulk;
		font-size: smaller;
		& a {
			@include bases.base-link;
			display: inline;
			font-size: inherit;
		}
	}

	&__extra-info {
		grid-area: extra-info;
		display: flex;
		flex-direction: column;
		gap: var(--p-rhythm-sm);
		justify-content: space-between;
		padding: var(--p-rhythm);
		border: 2px solid var(--store-dark-grey);
		min-height: 240px;
	}

	& .btn:hover {
		background: transparent;
	}
}

@include mixins.to-md {
	.product-description {
		display: block;

		> * + * {
			margin-top: var(--p-rhythm-lg);
		}

		&__selection {
			& .cart-button {
				margin-top: var(--p-rhythm);
			}
		}

		&__price {
			margin-top: var(--p-rhythm-sm);
		}

		&__crumbs {
			display: none;
		}
	}
}
