@use "mixins";

.related-products {
	&__title {
		font-size: var(--fs-h3);
		margin-bottom: var(--p-rhythm-lg);
		font-weight: 600;
		letter-spacing: 0.1rem;
	}
	&__grid {
		display: grid;
		gap: var(--p-rhythm-lg);
	}
}

@include mixins.from-sm {
	.related-products {
		&__grid {
			grid-template-columns: repeat(2, 1fr);
		}
	}
}

@include mixins.from-md {
	.related-products {
		&__grid {
			grid-template-columns: repeat(3, calc(33% - var(--p-rhythm-sm) * 0.5));
			gap: var(--p-rhythm-sm);
		}
	}
}

@include mixins.from-lg {
	.related-products {
		&__grid {
			grid-template-columns: repeat(4, calc(25% - var(--p-rhythm-sm) * 0.75));
		}
	}
}
