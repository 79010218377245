@use "mixins";

@include mixins.to-md {
	.double-media {
		&__item + &__item {
			margin-top: var(--p-rhythm);
		}
	}
}

@include mixins.from-md {
	.double-media {
		display: flex;
		&__item {
			width: calc(50% - (var(--p-rhythm) / 2));
		}

		&__item + &__item {
			margin-left: var(--p-rhythm);
		}
	}

	// video player dimension adjustments for double media desktop
	.double-media .credited-media {
		.credited-media__attribution {
			right: 1rem;
			bottom: 4rem;
		}

		.video-player .plyr__controls {
			padding-bottom: 1rem;
			margin-left: 1rem;
			margin-right: 1rem;
		}
	}
}

.captioned-media {
	margin: 0;
	&__asset {
		width: 100%;
		height: auto;
		display: block;
	}
	&__caption {
		font-size: 0.8em;
		padding-top: var(--p-rhythm-sm);
	}
}
